import React from 'react';
import './FormFail.scss';



function FormFail(props) {

   
    return (
        <div className="form-wrapper">
          FormFail
        </div>
    );
}

export default FormFail;